export class GeneralSchema {

    schemaSlug = 'general';

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.sample-number',
            size:       6,
            attributes: {
                readonly: true,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.collection-date',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.ligand_type = {
            type:       'text',
            key:        'ligand_type',
            label:      'form.field.ligand-type',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.commercial_designation = {
            type:       'text',
            key:        'commercial_designation',
            label:      'form.field.commercial-designation',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.central_number = {
            type:       'number',
            key:        'central_number',
            label:      'form.field.central-number',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.central_designation = {
            type:       'text',
            key:        'central_designation',
            label:      'form.field.central-designation',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.supplier = {
            type:       'text',
            key:        'supplier',
            label:      'form.field.supplier',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.ligand_application = {
            type:       'text',
            key:        'ligand_application',
            label:      'form.field.application',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.external_reference = {
            type:       'text',
            key:        'external_reference',
            label:      'form.field.external_reference',
            size:       12,
            attributes: {
                readonly: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        return [
            [this.number, this.date],
            [this.ligand_type, this.commercial_designation],
            [this.central_number, this.central_designation],
            [this.supplier, this.ligand_application],
            [this.external_reference],
            [this.observations],
        ];
    }

}
