import { DialogService }                 from 'aurelia-dialog';
import { bindable, inject }              from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { PLATFORM }                      from 'aurelia-pal';
import { BaseOrderedListViewModel }      from 'base-ordered-list-view-model';
import { SelectedBulletinsListingModal } from 'modules/aggregates/listing-modals/selected-bulletins-listing-modal';
import { FilterFormSchema }              from 'modules/bituminous-mixtures/ligands/filter-form-schema';
import { AdditionalInfoListingModal }    from 'modules/bituminous-mixtures/ligands/listing-modals/additional-info-listing-modal';
import { CommentsListingModal }          from 'modules/core/custom-components/comments/listing-modal';
import { Sector }                        from 'modules/management/models/sector';
import { InfoDialog }                    from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';
import { BMLigandsRepository }           from './services/repository';

@inject(AppContainer, BMLigandsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListBMLigands extends BaseOrderedListViewModel {

    routePrefix = 'bituminous-mixtures.ligands';

    @bindable permissionPrefix = this.routePrefix;
    @bindable listingId        = 'bituminous-mixtures-ligands-listing';
    @bindable headerTitle      = 'listing.' + this.routePrefix;
    @bindable newRecordRoute   = this.routePrefix + '.create';
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     *
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate() {

        this.sectorId = Sector.BITUMINOUS_MIXTURES_LIGANDS;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.index`) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.authUser = this.appContainer.authenticatedUser;

        this.selectedLotInformation = this.authUser.getAllSelectedLotInformation();

        this.filterModel  = this.filterFormSchema.model(this, this.selectedLotInformation);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.view', { id: row.id }),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.edit', { id: row.id }),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit'])
                    && !row.is_validated,
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.validateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.validate']),
                },
                {
                    label:   'form.button.verify-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.verifySelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.canVerify() && this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.verify']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.invalidateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.invalidate']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.callThenResponse(this.repository.invalidate(row.id)),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.invalidate'])
                        && row.is_validated && !this.authUser.isBuilder,
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.validate(row.id)),
                    tooltip: 'form.button.validate',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.validate'])
                        && !row.is_validated,
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.verify(row.id)),
                    tooltip: 'form.button.verify',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.verify'])
                        && this.canVerify(row),
                },
                {
                    icon:    'icon-info3',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal),
                    tooltip: 'form.button.view-additional-info',
                },
                {
                    icon:    'icon-comments',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, CommentsListingModal),
                    tooltip: 'form.button.view-comments',
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    4,
                    direction: 'desc',
                },
            ],
            columns:            [
                {
                    data:  'test_number',
                    name:  'test_numbers.number',
                    title: 'form.field.sample-number',
                },
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_name',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:  'ligand_type',
                    name:  'bm_ligands.ligand_type',
                    title: 'form.field.ligand-type',
                },
                {
                    data:  'commercial_designation',
                    name:  'bm_ligand_translations.commercial_designation',
                    title: 'form.field.commercial-designation',
                },
                {
                    data:  'ligand_application',
                    name:  'bm_ligands.ligand_application',
                    title: 'form.field.application',
                },
                {
                    data:  'supplier',
                    name:  'bm_ligands.supplier',
                    title: 'form.field.supplier',
                },
                {
                    data:  'external_reference',
                    name:  'test_headers.external_reference',
                    title: 'form.field.external-ref',
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.getEntityWithTeam(row),
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'bm_ligands.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:       'verified',
                    name:       'user_entity_team_lots.identifier',
                    title:      'form.field.verified',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/verified/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'additional_info',
                    name:       'additional_info',
                    title:      'form.title.additional-info',
                    type:       'custom-cell',
                    icon:       'icon-info3',
                    show:       (row) => row.has_additional_information,
                    action:     (row) => this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'comments',
                    name:       'comments',
                    title:      'form.title.comments',
                    type:       'custom-cell',
                    icon:       'icon-comments',
                    show:       (row) => row.has_comments,
                    action:     (row) => this.openModal({ relatableId: row.test_header_id }, CommentsListingModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'process_status',
                    name:       'process_status',
                    title:      'form.field.process-status',
                    type:       'custom-cell',
                    action:     (row) => this.openModal({ relatableId: row.test_header_id }, SelectedBulletinsListingModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/processing-status/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Can the authenticated user verify?
     * @param row
     * @returns boolean
     */
    canVerify(row = false) {
        return (row && row.is_validated
                && ((this.authUser.isRegulator
                        && row.verified_inspection_at === null && row.verified_inspection_by === null)
                    || (this.authUser.isHighwayOperator
                        && row.verified_concessionary_at === null && row.verified_concessionary_by === null)))
            || (!row && (this.authUser.isRegulator || this.authUser.isHighwayOperator));
    }

    /**
     * Export ZIP
     *
     * @returns {*}
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('text.success-message.file-being-generated-please-wait'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }

    /**
     * Processed cell for Entity (Team)
     *
     * @param row
     * @returns {*}
     */
    getEntityWithTeam(row) {

        let html = row['entity_name'];
        html += '<small><br />(';
        html += row['team_name'];
        html += ')</small>';

        return html;
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @param column
     * @returns {string}
     */
    joinArrayInList(row, column) {
        let html = '<ul>';

        for (let item of row[column]) {
            html += '<li>';
            html += item;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }
}
