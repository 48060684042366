import { inject }              from 'aurelia-framework';
import { PLATFORM }            from 'aurelia-pal';
import { BaseFormViewModel }   from 'base-form-view-model';
import { FormSchema }          from 'modules/bituminous-mixtures/ligands/form-schema';
import { BMLigandsRepository } from 'modules/bituminous-mixtures/ligands/services/repository';
import { Sector }              from 'modules/management/models/sector';
import { AppContainer }        from 'resources/services/app-container';

@inject(AppContainer, BMLigandsRepository, FormSchema)
export class ViewBMLigand extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    routePrefix = 'bituminous-mixtures.ligands';
    permissionPrefix;

    alert = {};
    model = {};
    tabs  = {};

    create   = false;
    readonly = true;

    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/ligands/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate() {
        this.sectorId = Sector.BITUMINOUS_MIXTURES_LIGANDS;

        this.permissionPrefix = this.routePrefix;
        this.formId           = 'bituminous-mixtures-ligands-view-form';

        this.backRoute = `${this.routePrefix}.index`;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.view`) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.view',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if(this.model.is_validated) {
            this.alert = this.infoAlertMessage('form.message.viewing-validated-record');
        }

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.formSchema.selectBulletinsSchema.schema(this, true);
        this.tabs.general               = this.formSchema.generalSchema.schema(this, true);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this, true);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this, true);
        this.globalSchema               = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
